<template>
  <v-container fluid style='width: 100vw'>
    <v-row class='pa-5'>
      <v-spacer />
      <v-btn small color='primary' @click='newForm'>
        <v-icon> mdi-plus </v-icon>
        {{ $t('NEW_FORM')}}
      </v-btn>
      <!-- <v-btn small class='ml-2' @click='handleImportForms'>
        {{ $t('IMPORT')}}
      </v-btn>
      <v-btn class='ml-2' :disabled='!selectedForms.length' small @click='handleExportForms'>
        {{ $t('EXPORT') }}
      </v-btn> -->
    </v-row>
    <v-row class='pa-5' style='width: 100%'>
      <v-data-table v-model='selectedForms' style='width: 100vw' :items='forms' :headers='formDTHeaders' show-select>
        <template #item.entity='{ item }'>
          <span>{{ $t(item.entity) }}</span>
        </template>
        <template #item.active='{ item }'>
          <v-icon :color='item.active ? "green" : "red"'>{{ item.active ? 'mdi-check' : 'mdi-close' }}</v-icon>
        </template>
        <template #item.created_at='{ item }'>
          <span>{{ `${formatDate(item.createdAt)} - ${formatTime(item.createdAt)}` }}</span>
        </template>
        <template #item.actions='{ item }'>
          <v-col>
            <v-tooltip bottom>
              <template #activator='{ on, attrs }'>
                <v-btn
                  color='primary'
                  small
                  icon
                  v-bind='attrs'
                  @click='openFormDialog(item)'
                  v-on='on'
                >
                  <v-icon small> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('EDIT') }}</span>
            </v-tooltip>
          </v-col>
        </template>
      </v-data-table>
    </v-row>

    <v-dialog v-model='newFormDialog' max-width='400' no-click-animation>
      <v-card>
        <v-toolbar color='primary' dark class='px-3 mb-5'>{{ $t('SELECT_ENTITY')}}</v-toolbar>
        <v-card-text>
          <v-autocomplete v-model='selectedEntity' outlined dense :label='$t("ENTITY")' :items='entities' item-text='description' item-value='value' return-object hide-details='auto' />
        </v-card-text>
        <v-card-actions class='justify-end'>
          <v-btn small color='success' @click='openFormDialog(null)'> {{ $t('CREATE') }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if='selectedForm' v-model='formDialog' fullscreen persistent style='overflow-x: hidden !important' no-click-animation>
      <v-toolbar color='primary' dark class='pt-1 px-3'>
        <v-btn icon dark @click='closeFormDialog'>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('FORM_ENTITY', { entity: $t(selectedForm.entity) })}}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <CustomerDynamicFormBuilder :dynamic-form='selectedForm' :entity='selectedForm.entity' :infant='infant' @form-updated='getData' />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import Swal from 'sweetalert2';
  import fs from 'fs';
  import { mapGetters } from 'vuex';
  import {
    showSuccess,
    showError,
  } from '@/util/notification';
  import CustomerDynamicFormBuilder from './CustomerDynamicFormBuilder';
  import {
    getAllEntities,
    getAllForms,
    exportForms,
    importForms,
    createDynamicForm,
  } from './api';
  import { formatDate, formatTime } from '../../util/date/index';

  export default {
    components: {
      CustomerDynamicFormBuilder,
    },
    props: {
      infant: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      infant: function () {
        this.getData();
      },
    },
    computed: {
      ...mapGetters('auth', [
        'getUser',
      ]),
    },
    data: function () {
      return {
        forms: [],
        formDTHeaders: [
          { text: this.$t('VERSION'), value: 'version', align: 'center' },
          { text: this.$t('ENTITY'), value: 'entity', align: 'center' },
          { text: this.$t('FIELD_QT'), value: 'dynamicFieldCount', align: 'center' },
          { text: this.$t('CREATED_ON'), value: 'created_at', align: 'center' },
          { text: this.$t('ACTIVE'), value: 'active', align: 'center' },
          {
            text: '',
            sortable: false,
            value: 'actions',
            width: '120',
            align: 'center',
          },
        ],
        selectedForm: undefined,
        selectedEntity: undefined,
        entities: [],
        formDialog: false,
        newFormDialog: false,
        hasMainField: false,
        selectedForms: [],
      };
    },
    mounted: function () {
      this.getData();
    },
    methods: {
      formatDate,
      formatTime,
      getData: function (hasMainField) {
        this.hasMainField = hasMainField;
        this.getEntities();
        this.getForms();
      },
      newForm: function () {
        this.newFormDialog = true;
      },
      handleExportForms: async function () {
        if (!this.selectedForms.length) return;
        try {
          const savePath = dialog.showSaveDialog({
            title: this.$t('EXPORT'),
            defaultPath: 'forms.json',
            filters: [
              { name: 'JSON Files', extensions: ['json'] },
              { name: 'All Files', extensions: ['*'] },
            ],
          });
          if (savePath) {
            const data = await exportForms(this.selectedForms.map((form) => form.id));
            fs.writeFileSync(savePath, JSON.stringify(data, null, 2));
            showSuccess({ message: this.$t('EXPORT_TYPE_EXPORTED_SUCCESSFULLY', { exportType: this.$t('DYNAMIC_FORMS') }) });
          }
        } catch (err) {
          showError({ message: this.$t('EXPORT_ERROR') });
        }
      },
      handleImportForms: async function () {
        try {
          const path = dialog.showOpenDialog({
            title: this.$t('IMPORT'),
            properties: ['openFile'],
            filters: [
              { name: 'JSON Files', extensions: ['json'] },
            ],
          });
          if (path) {
            const jsonData = fs.readFileSync(path[0]);
            const { data } = JSON.parse(jsonData);
            if (data) {
              await importForms(data.dynamicForms);
              this.getForms();
              showSuccess({ message: this.$t('IMPORT_TYPE_IMPORTED_SUCCESSFULLY', { importType: this.$t('DYNAMIC_FORMS') }) });
            }
          }
        } catch (err) {
          showError({ message: this.$t('IMPORT_ERROR') });
        }
      },
      openFormDialog: async function (form) {
        try {
          if (!form) {
            const creatorId = this.getUser && this.getUser.account && this.getUser.account.id;
            const data = await createDynamicForm(this.selectedEntity.id, creatorId || 1, this.infant);
            this.selectedForm = data.dynamicForm;
            showSuccess({ message: this.$t('RECORD_CREATED') });
          } else {
            this.selectedForm = form;
          }
          this.formDialog = true;
          this.newFormDialog = false;
        } catch (err) {
          showSuccess({ message: this.$t('RECORD_ERROR') });
        }
      },
      closeFormDialog: function () {
        if (!this.hasMainField) {
          return Swal.fire({
            title: this.$t('MAIN_FIELD_REQUIRED'),
            text: this.$t('MAIN_FIELD_REQUIRED_ERROR_TEXT'),
            type: 'error',
          });
        }

        this.formDialog = false;
        this.selectedForm = undefined;

        return true;
      },
      getEntities: function () {
        getAllEntities().then((result) => {
          this.entities = result;
        });
      },
      getForms: function () {
        getAllForms(this.infant).then(((result) => {
          this.forms = result.dynamicForms;
        }));
      },
    },
  };
</script>

<style>
  .v-dialog {
    overflow-x: hidden !important
  }
</style>
