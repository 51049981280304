var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"mb-3",attrs:{"headers":_vm.headers,"items":_vm.dynamicFormFields,"hide-default-header":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return _vm._l((props.headers),function(column){return _c('th',{key:column.text,staticClass:"pa-4",attrs:{"align":"left"}},[_vm._v(" "+_vm._s(_vm.$t(column.text))+" ")])})}},{key:"item.field",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.labelKey))])]}},{key:"item.main",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-checkbox',{staticClass:"ml-3 mt-3",staticStyle:{"align-self":"center","justify-self":"center"},attrs:{"disabled":["file", "title"].includes(item.type)},on:{"change":function($event){return _vm.setFieldAsMain(item)}},model:{value:(item.main),callback:function ($$v) {_vm.$set(item, "main", $$v)},expression:"item.main"}})],1)]}},{key:"item.show_on_listing",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-checkbox',{staticClass:"ml-3 mt-3",attrs:{"disabled":["title", "file"].includes(item.type)},on:{"change":function($event){return _vm.updateField(item)}},model:{value:(item.showOnListing),callback:function ($$v) {_vm.$set(item, "showOnListing", $$v)},expression:"item.showOnListing"}})],1)]}},{key:"item.show_on_summary",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-checkbox',{staticClass:"ml-3 mt-3",on:{"change":function($event){return _vm.updateField(item)}},model:{value:(item.showOnSummary),callback:function ($$v) {_vm.$set(item, "showOnSummary", $$v)},expression:"item.showOnSummary"}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }