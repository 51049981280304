<template>
  <v-container fluid>
    <v-row no-gutters>
      <div class='text-h5'> {{$t('CONFIGURATIONS')}} </div>
    </v-row>

    <v-divider class='my-3' />

    <v-btn-toggle v-model='serviceTab' class='my-2' rounded selected-class='bg-primary' borderless mandatory>
      <v-btn v-for='customerService in customerServices' :key='customerService.id' class='font-weight-bold' color='secondary' outlined :value='customerService.id' :ripple='false' @click='serviceTab = customerService.id; selectedTab = 0'> {{ customerService.name }} </v-btn>
    </v-btn-toggle>

    <v-row no-gutters>
      <v-card tile flat color='white'>
        <v-tabs v-model='selectedTab' background-color='transparent'>
          <v-tab>{{ $t('COLLECT_FLOW') }}</v-tab>
          <v-tab v-if='serviceTab === SERVICES.ENROLL'>{{ $t('CAPTURE_MODULE') }}</v-tab>
          <v-tab>{{ $t('THEME') }}</v-tab>
          <v-tab>{{ $t('DYNAMIC_FORMS') }}</v-tab>
        </v-tabs>
        <v-container fluid class='pa-0'>
          <v-tabs-items v-model='selectedTab' class='transparent'>
            <v-tab-item>
              <CustomerCollectFlow :infant='serviceTab === SERVICES.INFANT' />
            </v-tab-item>
            <v-tab-item v-if='serviceTab === SERVICES.ENROLL'>
              <CustomerCaptureModule />
            </v-tab-item>
            <v-tab-item>
              <CustomerTheme :infant='serviceTab === SERVICES.INFANT' />
            </v-tab-item>
            <v-tab-item>
              <CustomerDynamicForms :infant='serviceTab === SERVICES.INFANT' />
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import CustomerTheme from '@/components/Customers/CustomerTheme';
  import CustomerDynamicForms from '@/components/Customers/CustomerDynamicForms.vue';
  import CustomerCollectFlow from '@/components/Customers/CustomerCollectFlow';
  import CustomerCaptureModule from '@/components/Customers/CustomerCaptureModule';
  import { mapGetters } from 'vuex';

  export default {
    name: 'CustomerSettings',
    components: {
      CustomerTheme,
      CustomerDynamicForms,
      CustomerCollectFlow,
      CustomerCaptureModule,
    },
    computed: {
      ...mapGetters('auth', ['getUser']),
    },
    data: function () {
      return {
        serviceTab: 3,
        selectedTab: 0,
        customerServices: [],
        SERVICES: {
          ENROLL: 3,
          INFANT: 5,
        },
      };
    },
    mounted: function () {
      this.getCustomerServices();
    },
    methods: {
      getCustomerServices: async function () {
        const { data } = await axios({
          url: `/customer/${this.getUser.account.customerId}/available-services`,
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
        });

        this.customerServices = data.services.filter((service) => [3, 5].includes(service.id));

        if (this.customerServices?.length) {
          this.serviceTab = this.customerServices[0].id;
        }
      },
    },
  };
</script>

<style scoped>
</style>
