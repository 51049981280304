<template>
  <div>
    <v-tabs-items v-model='tab'>
      <!--
      @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
      @          DYNAMIC FORM          @
      @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
      -->
      <v-tab-item :key='1'>
        <v-row class='fill-height mb-3'>
          <v-col style='max-height: 100vh; padding-bottom: 50px; overflow-y: auto;'>
            <div v-if='!formOnly' class='pa-1' style='display: flex; flex-direction: row; align-items: center;'>
              <v-checkbox v-model='activeDynamicForm' class='ml-3 mt-3' :label='$t("ACTIVE")' :disabled='activatingDynamicForm' @click='handleActiveDynamicForm' />
              <v-checkbox v-model='showFieldGrid' class='ml-3 mt-3' :label='$t("SHOW_FIELD_BORDERS")' />
              <v-checkbox v-model='showFormOnly' class='ml-3 mt-3' :label='$t("SHOW_FORM_ONLY")' />
            </div>
            <draggable
              class='pa-3 mt-0 row'
              handle='.drag'
              :list='fields'
              :group='{ name: "fields", put: true }'
              @end='saveReorder'
            >
              <v-col
                v-for='(field, index) in fields'
                :key='index'
                :class='showFieldGrid ? "border-1x-grey" : ""'
                :cols='field.gridColumns'
              >
                <v-row v-if='!showFormOnly && !formOnly' class='ma-0 mb-2'>
                  <v-icon class='drag'> mdi-drag </v-icon>
                  <v-spacer />
                  <v-btn v-if='field.type !== "title"' small icon @click='decreaseFieldColumnSize(index)'>
                    <v-icon small>mdi-menu-left</v-icon>
                  </v-btn>
                  <v-btn v-if='field.type !== "title"' small icon @click='increaseFieldColumnSize(index)'>
                    <v-icon small>mdi-menu-right</v-icon>
                  </v-btn>
                </v-row>

                <!-- =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= -->
                <!-- =                  TITLE                  = -->
                <!-- =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= -->
                <div v-if='field.type === "title"'>
                  <v-card-title class='pl-0 pb-0 primary--text'> {{ field.name || $t(field.labelKey) }} </v-card-title>
                  <v-divider class='mt-0' inset />
                </div>

                <!-- =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= -->
                <!-- =      TEXT | NUMBER | INTEGER | DATE     = -->
                <!-- =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= -->
                <v-text-field
                  v-if='["text", "numeric", "integer", "date"].includes(field.type)'
                  v-model='field.value'
                  v-mask:bind='field.rules && field.rules.mask'
                  outlined
                  dense
                  hide-details='auto'
                  :disabled='!showFormOnly && !formOnly'
                  :label='$t(field.labelKey) || field.name'
                  :type='["numeric", "integer"].includes(field.type) ? "number" : field.type'
                  :maxlength='field.rules && field.rules.maxLength'
                  :rules='getFieldRules(field)'
                  :step='field.rules && field.rules.step'
                  @input='onTextInput(field)'
                />

                <!-- =-=-=-=-=-=-=-=-=-=-= -->
                <!-- =      TEXTAREA     = -->
                <!-- =-=-=-=-=-=-=-=-=-=-= -->
                <v-textarea
                  v-else-if='field.type === "textarea"'
                  v-model='field.value'
                  :label='$t(field.labelKey)'
                  outlined
                  auto-grow
                  dense
                  hide-details='auto'
                  :disabled='!showFormOnly && !formOnly'
                  :rules='getFieldRules(field)'
                  :maxlength='field.rules && field.rules.maxLength'
                />

                <!-- =-=-=-=-=-=-=-=-=-= -->
                <!-- =      SELECT     = -->
                <!-- =-=-=-=-=-=-=-=-=-= -->
                <v-autocomplete
                  v-else-if='field.type === "select"'
                  v-model='field.value'
                  item-text='i18nLabel'
                  outlined
                  dense
                  clearable
                  hide-details='auto'
                  :disabled='!showFormOnly && !formOnly'
                  :label='$t(field.labelKey)'
                  :items='getFieldOptions(field)'
                  :rules='getFieldRules(field)'
                  :deletable-chips='field.rules && field.rules.multiple'
                  :multiple='field.rules && field.rules.multiple'
                >
                  <template #selection='data'>
                    <v-chip
                      v-if='field.rules && field.rules.multiple'
                      v-bind='data && data.attrs'
                      :input-value='data && data.selected'
                      color='info'
                      close
                      small
                      @click='data.select'
                      @click:close='field.value.splice(field.value.indexOf(data.item.value), 1)'
                    >
                      {{ $t(data.item.labelKey) || data.item.name }}
                    </v-chip>
                    <span v-else> {{ $t(data.item.labelKey) || data.item.name }} </span>
                  </template>
                  <template #item='data'>
                    <span> {{ $t(data.item.labelKey) || data.item.name }}</span>
                  </template>
                </v-autocomplete>

                <!-- =-=-=-=-=-=-=-=-=-= -->
                <!-- =      RADIO      = -->
                <!-- =-=-=-=-=-=-=-=-=-= -->
                <v-radio-group
                  v-else-if='field.type === "radio"'
                  v-model='field.value'
                  class='mt-0'
                  hide-details='auto'
                  :disabled='!showFormOnly && !formOnly'
                  :label='$t(field.labelKey)'
                  :title='$t(field.description)'
                  :rules='getFieldRules(field)'
                  :row='field.rules.orientation === "horizontal"'
                  :column='field.rules.orientation === "vertical"'
                >
                  <v-radio
                    v-for='fieldOptions in field.options'
                    :key='`r${fieldOptions.id}`'
                    class='mx-3'
                    hide-details='auto'
                    :label='$t(fieldOptions.labelKey) || fieldOptions.name'
                    :title='$t(field.description)'
                    :value='fieldOptions.value'
                  />
                </v-radio-group>

                <!-- =-=-=-=-=-=-=-=-=-= -->
                <!-- =      CHECK      = -->
                <!-- =-=-=-=-=-=-=-=-=-= -->
                <v-checkbox
                  v-else-if='field.type === "check"'
                  v-model='field.value'
                  class='mt-0'
                  hide-details='auto'
                  :disabled='!showFormOnly && !formOnly'
                  :label='$t(field.labelKey)'
                />

                <!-- =-=-=-=-=-=-=-=-=-= -->
                <!-- =      SWITCH     = -->
                <!-- =-=-=-=-=-=-=-=-=-= -->
                <v-switch
                  v-else-if='field.type === "switch"'
                  v-model='field.value'
                  class='mt-0'
                  hide-details='auto'
                  :disabled='!showFormOnly && !formOnly'
                  :label='$t(field.labelKey)'
                />

                <!-- =-=-=-=-=-=-=-=-=-= -->
                <!-- =      SLIDER     = -->
                <!-- =-=-=-=-=-=-=-=-=-= -->
                <v-slider
                  v-else-if='field.type === "slider"'
                  v-model='field.value'
                  hide-details='auto'
                  :disabled='!showFormOnly && !formOnly'
                  thumb-label='always'
                  :thumb-size='20'
                  :label='$t(field.labelKey)'
                  :min='field.rules && field.rules.minValue'
                  :max='field.rules && field.rules.maxValue'
                  :step='field.rules && field.rules.step'
                  :vertical='field.rules.orientation === "vertical"'
                  :rules='getFieldRules(field)'
                />

                <!-- =-=-=-=-=-=-=-=-=-= -->
                <!-- =       FILE      = -->
                <!-- =-=-=-=-=-=-=-=-=-= -->
                <div v-else-if='field.type === "file"'>
                  <v-btn
                    small
                    color='info'
                    :disabled='!showFormOnly && !formOnly'
                    :rules='getFieldRules(field)'
                    :loading='field.loading'
                    @click='uploadFile(field)'
                  >
                    <v-icon small class='mr-2'> mdi-upload </v-icon>
                    {{ $t(field.labelKey) }}
                  </v-btn>
                  <div v-if='field.value' style='cursor: pointer;' @click='downloadFile(field)'>
                    <v-icon :color='field.file.color'> {{ field.file.icon }} </v-icon>
                    <span
                      class='ml-1 mb-5'
                      style='color: rgba(0, 0, 0, 0.6); flex: 1 1 auto; font-size: 12px; min-height: 14px; min-width: 1px; position: relative;'
                    >
                      {{ field.file_name || $t('FILE') }}
                    </span>
                  </div>
                  <v-file-input
                    :ref='`file-${field.id}`'
                    v-model='field.file'
                    :accept='field.rules && field.rules.fileAccept || "*"'
                    class='d-none'
                    @change='inputFile(field, $event)'
                  />
                </div>

                <!-- =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= -->
                <!-- =      FIELD CONFIG DIALOG      = -->
                <!-- =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= -->
                <v-row v-if='!showFormOnly && !formOnly' class='ma-0'>
                  <v-spacer />
                  <v-btn class='pr-0' color='red' x-small text plain @click='removeField(index);'>
                    {{ $t('REMOVE') }}
                    <v-icon class='ml-1' small> mdi-delete </v-icon>
                  </v-btn>
                  <v-dialog
                    v-model='field.dialog'
                    persistent
                    max-width='600px'
                  >
                    <template #activator='{ on }'>
                      <v-btn class='pr-0' x-small text plain v-on='on' @click='openFieldConfigDialog(field)'>
                        <span> {{ $t('CONFIGURATION') }} </span>
                        <v-icon class='ml-1' small> mdi-cog </v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class='toolbar-title primary'>
                        <span class='text-h5'> {{ $t('FIELD_X_CONFIG', { field: $t(field.name && field.name.toUpperCase()) || field.name }) }} </span>
                      </v-card-title>
                      <v-card-text class='pa-0'>
                        <v-container class='pa-0'>
                          <v-tabs v-model='field.dialogTab' grow>
                            <v-tab> {{ $t('GENERAL') }} </v-tab>
                            <v-tab v-if='!["check", "switch", "title"].includes(field.type)'> {{ $t('RULES') }} </v-tab>
                            <v-tab v-if='field.type === "select" || field.type === "radio"'> {{ $t('OPTIONS') }} </v-tab>

                            <v-tabs-items v-model='field.dialogTab'>
                              <v-tab-item class='px-5'>
                                <v-col cols='12'>
                                  <v-text-field v-model='field.name' :label='$t("FIELD_NAME")' required />
                                  <v-text-field v-model='field.description' :label='$t("FIELD_DESCRIPTION")' />
                                </v-col>
                              </v-tab-item>

                              <v-tab-item class='pt-2 pb-5 mx-5'>
                                <v-row>
                                  <v-col cols='12'>
                                    <v-checkbox v-model='field.rules.required' hide-details='auto' :label='$t("REQUIRED_FIELD")' />
                                  </v-col>
                                  <v-col v-if='rulesVisibilityConfig.mask' cols='6'>
                                    <v-text-field v-model='field.rules.mask' persistent-hint :label='$t("MASK")' :hint='$t("MASK_HINT")' />
                                  </v-col>
                                  <v-col v-if='rulesVisibilityConfig.maxLength' cols='6'>
                                    <v-text-field v-model='field.rules.maxLength' hide-details='auto' type='number' :label='$t("CHARACTER_LIMIT")' />
                                  </v-col>
                                  <v-col v-if='rulesVisibilityConfig.minValue' cols='6'>
                                    <v-text-field v-model='field.rules.minValue' hide-details='auto' type='number' :label='$t("MINIMUM_VALUE")' />
                                  </v-col>
                                  <v-col v-if='rulesVisibilityConfig.maxValue' cols='6'>
                                    <v-text-field v-model='field.rules.maxValue' hide-details='auto' type='number' :label='$t("MAXIMUM_VALUE")' />
                                  </v-col>
                                  <v-col v-if='rulesVisibilityConfig.decimalLength' cols='6'>
                                    <v-text-field v-model='field.rules.decimal_length' hide-details='auto' type='number' :label='$t("DECIMAL_LENGTH")' />
                                  </v-col>
                                  <v-col v-if='rulesVisibilityConfig.step' cols='6'>
                                    <v-text-field v-model='field.rules.step' hide-details='auto' type='number' :label='$t("INCREMENT_VALUE")' />
                                  </v-col>
                                  <v-col v-if='rulesVisibilityConfig.orientation' cols='6'>
                                    <v-autocomplete v-model='field.rules.orientation' hide-details='auto' :label='$t("ORIENTATION")' :items='["horizontal", "vertical"]' />
                                  </v-col>
                                  <v-col v-if='rulesVisibilityConfig.multiple' cols='6'>
                                    <v-checkbox v-model='field.rules.multiple' hide-details='auto' :label='$t("MULTIPLE_SELECTION")' />
                                  </v-col>
                                  <v-col v-if='rulesVisibilityConfig.fileAccept' cols='6'>
                                    {{ field.rules.fileAccept}}
                                    <v-autocomplete v-model='field.rules.fileAccept' hide-details='auto' :label='$t("FILE_TYPE")' :items='fileTypes' item-text='description' item-value='value' />
                                  </v-col>
                                </v-row>
                              </v-tab-item>

                              <v-tab-item class='pa-5 mt-2 mx-5'>
                                <v-row>
                                  <v-btn color='success' text small @click='addFieldOption(field, index)'>
                                    <v-icon small> mdi-plus </v-icon>
                                    {{ $t('ADD_OPTION')}}
                                  </v-btn>
                                </v-row>
                                <v-row v-if='field.options.length > 0'>
                                  <draggable
                                    class='py-3 mt-0 row'
                                    handle='.dragOptions'
                                    :list='field.options'
                                    @end='optionsReorder(index)'
                                  >
                                    <v-row
                                      v-for='(fieldOptions, fieldOptionsIndex) in field.options'
                                      :key='fieldOptionsIndex'
                                      cols='12'
                                      class='justify-center align-center text-center mb-2'
                                    >
                                      <v-icon class='dragOptions'> mdi-drag </v-icon>
                                      <v-col cols='8'>
                                        <v-text-field v-model='fieldOptions.name' hide-details='auto' :label='`Opção ${fieldOptionsIndex + 1}`' />
                                      </v-col>
                                      <v-col cols='2'>
                                        <v-text-field v-model='fieldOptions.value' hide-details='auto' :label='`Valor ${fieldOptionsIndex + 1}`' />
                                      </v-col>
                                      <v-col cols='1' class='mt-5 pa-0'>
                                        <v-btn icon small plain @click='removeFieldOption(field, fieldOptionsIndex)'>
                                          <v-icon small color='red'> mdi-close </v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </draggable>
                                </v-row>
                              </v-tab-item>
                            </v-tabs-items>
                          </v-tabs>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color='blue darken-4'
                          text
                          small
                          @click='checkChanges(field, index)'
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          color='info'
                          dark
                          small
                          @click='saveFieldConfig(field)'
                        >
                          Salvar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </v-col>
            </draggable>
          </v-col>

          <!-- =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= -->
          <!-- =       FIELD LIST DRAWER       = -->
          <!-- =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= -->
          <v-col v-if='!formOnly' class='px-0 mr-3 elevation-2 fill-height bg-white' :cols='getFieldListCols' style='background-color: white; z-index: 1;'>
            <v-row class='my-1 ml-1'>
              <v-col class='pa-0 d-flex-center' cols='12'>
                <v-btn small icon class='justify-center mr-5' @click='collapseFieldList = !collapseFieldList'>
                  <v-icon small> mdi-menu </v-icon>
                </v-btn>
              </v-col>
              <v-col class='pa-0 d-flex-center' cols='12'>
                <component :is='collapseFieldList ? "h5" : "h4"' style='margin-left: -10px'>{{ $t('AVAILABLE_FIELDS') }}</component>
              </v-col>
            </v-row>
            <draggable class='list-group' :list='fieldList' :group='{ name: "fields", pull: "clone" }'>
              <v-card
                v-for='(field, index) in fieldList'
                :key='`${index}_${field.type}`'
                :class='collapseFieldList ? "pl-2 pr-4 py-0" : "pa-2"'
                elevation='0'
              >
                <component :is='getFieldListDrawerComponent' class='ma-0 pl-2 pb-3' :class='index === fieldList.length - 1 ? "mb-5" : ""' :title='$t(field.labelKey)' style='text-align: center'>
                  <v-icon> {{ field.icon }} </v-icon>
                  <span v-if='!collapseFieldList' class='ml-2'> {{ $t(field.labelKey) }} </span>
                  <v-spacer />
                  <v-btn x-small color='success' @click='addField(field)'>
                    <v-icon :small='!collapseFieldList' :x-small='collapseFieldList'> mdi-plus </v-icon>
                  </v-btn>
                </component>
                <v-divider v-if='index !== fieldList.length - 1' />
              </v-card>
            </draggable>
          </v-col>
        </v-row>
      </v-tab-item>

      <!--
      @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
      @            EXIBITION           @
      @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
      -->
      <v-tab-item :key='2' eager>
        <v-alert v-if='!infant' type='warning' color='warning'>Apenas os campos customizados (não padrões) estão disponíveis nesta área</v-alert>
        <form-exibition :dynamic-form='dynamicForm.id' :dynamic-form-fields='dynamicFormFields' @form-updated='sendUpdatedEvent' />
      </v-tab-item>

      <!--
      @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
      @         DEFAULT FIELDS         @
      @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
      -->
      <v-tab-item v-if='!infant' :key='3' eager>
        <v-row class='fill-height mb-3'>
          <v-col style='max-height: 100vh; padding-bottom: 50px; overflow-y: auto;'>
            <div v-if='!formOnly' class='pa-1' style='display: flex; flex-direction: row; align-items: center;'>
              <v-checkbox v-model='activeDynamicForm' class='ml-3 mt-3' :label='$t("ACTIVE")' :disabled='activatingDynamicForm' @click='handleActiveDynamicForm' />
              <v-checkbox v-model='showFieldGrid' class='ml-3 mt-3' :label='$t("SHOW_FIELD_BORDERS")' />
              <v-checkbox v-model='showFormOnly' class='ml-3 mt-3' :label='$t("SHOW_FORM_ONLY")' />
            </div>
            <draggable
              class='pa-3 mt-0 row'
              handle='.drag'
              :list='defaultFields'
              :group='{ name: "defaultFields", put: true }'
              @end='saveReorder'
            >
              <v-col
                v-for='(field, index) in defaultFields'
                :key='index'
                :class='showFieldGrid ? "border-1x-grey" : ""'
                :cols='field.gridColumns'
              >
                <v-row v-if='!showFormOnly && !formOnly' class='ma-0 mb-2'>
                  <v-icon class='drag'> mdi-drag </v-icon>
                  <v-checkbox v-if='field.type !== "title"' v-model='field.required' class='mt-0' dense hide-details color='primary' :label='$t("REQUIRED")' style='font-size: 12px' @change='saveDefaultFields' />
                  <v-spacer />
                  <v-btn v-if='field.type !== "title"' small icon @click='decreaseFieldColumnSize(index, true)'>
                    <v-icon small>mdi-menu-left</v-icon>
                  </v-btn>
                  <v-btn v-if='field.type !== "title"' small icon @click='increaseFieldColumnSize(index, true)'>
                    <v-icon small>mdi-menu-right</v-icon>
                  </v-btn>
                </v-row>
                <div v-if='field.type === "title"'>
                  <v-card-title class='pl-0 pb-0 primary--text'> {{ $t(field.labelKey) || field.name }} </v-card-title>
                  <v-divider class='mt-0' inset />
                </div>

                <!-- =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= -->
                <!-- =      TEXT | NUMBER | INTEGER | DATE     = -->
                <!-- =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= -->
                <v-text-field
                  v-if='["text", "numeric", "integer", "date"].includes(field.type)'
                  v-model='field.value'
                  outlined
                  dense
                  hide-details='auto'
                  :disabled='!showFormOnly && !formOnly'
                  :type='["numeric", "integer"].includes(field.type) ? "number" : field.type'
                  @input='onTextInput(field)'
                >
                  <template #label>
                    {{ $t(field.labelKey) || field.name }} <span v-if='field.required' style='color: red'>*</span>
                  </template>
                </v-text-field>
                <v-row v-if='!showFormOnly && !formOnly' class='ma-0'>
                  <v-spacer />
                  <v-btn class='pr-0 d-flex-center' color='red' x-small text plain @click='removeField(index, true);'>
                    {{ $t('REMOVE') }}
                    <v-icon class='ml-1' small> mdi-delete </v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </draggable>
          </v-col>
          <v-col v-if='!formOnly' class='px-0 mr-3 elevation-2 fill-height bg-white' :cols='getFieldListCols' style='background-color: white; z-index: 1;'>
            <v-row class='my-1 ml-1'>
              <v-col class='pa-0 d-flex-center' cols='12'>
                <v-btn small icon class='justify-center mr-5' @click='collapseFieldList = !collapseFieldList'>
                  <v-icon small> mdi-menu </v-icon>
                </v-btn>
              </v-col>
              <v-col class='pa-0 d-flex-center' cols='12'>
                <component :is='collapseFieldList ? "h5" : "h4"' style='margin-left: -10px'>{{ $t('AVAILABLE_FIELDS') }}</component>
              </v-col>
            </v-row>
            <draggable class='list-group' :list='defaultFieldList' :group='{ name: "defaultFields" }'>
              <v-card
                v-for='(field, index) in defaultFieldList'
                :key='`${index}_${field.type}`'
                :class='collapseFieldList ? "pl-2 pr-4 py-0" : "pa-2"'
                elevation='0'
              >
                <component :is='getFieldListDrawerComponent' class='ma-0 pl-2 pb-3' :class='index === defaultFieldList.length - 1 ? "mb-5" : ""' :title='$t(field.labelKey)' style='text-align: center'>
                  <v-icon> {{ field.icon }} </v-icon>
                  <span v-if='!collapseFieldList' class='ml-2'> {{ $t(field.labelKey) }} </span>
                  <v-spacer />
                  <v-btn x-small color='success' @click='addField(field, true)'>
                    <v-icon :small='!collapseFieldList' :x-small='collapseFieldList'> mdi-plus </v-icon>
                  </v-btn>
                </component>
                <v-divider v-if='index !== defaultFieldList.length - 1' />
              </v-card>
            </draggable>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <v-tabs
      v-show='!formOnly '
      v-model='tab'
      background-color='primary'
      style='position: fixed;  bottom: 0; z-index: 2;'
      dark
    >
      <v-tab v-for='item in filteredTabs' :key='item.id'>
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <v-footer v-if='!showFormOnly && formOnly' padless fixed>
      <v-row no-gutters>
        <v-btn tile block color='primary' :loading='saving' @click='saveValues'>
          {{ entityId ? $t('UPDATE_DATA') : $t('UPDATE_DATA') }}
        </v-btn>
      </v-row>
    </v-footer>
    <a id='downloadLink' style='display: none;'>Baixar Arquivo</a>
  </div>
</template>

<script>
  import draggable from 'vuedraggable';
  import { VRow, VCol } from 'vuetify/lib';
  import { mask } from 'vue-the-mask';
  import Swal from 'sweetalert2';
  import {
    showSuccess,
    showError,
  } from '@/util/notification';
  import FormExibition from './FormExibition';
  import api from './api';

  export default {
    name: 'DynamicFormBuilder',
    directives: {
      mask: (el, binding) => {
        if (binding.value) {
          mask(el, binding);
        }
      },
    },
    props: {
      dynamicForm: {
        type: Object,
        default: () => ({}),
      },
      entity: {
        type: String,
        default: 'PERSON',
      },
      entityId: {
        type: Number,
        default: undefined,
      },
      formOnly: {
        type: Boolean,
        default: false,
      },
      infant: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      draggable,
      FormExibition,
    },
    computed: {
      fields: function () {
        return this.dynamicFormFields || [];
      },
      getFieldListDrawerComponent: function () {
        return this.collapseFieldList ? VCol : VRow;
      },
      getFieldListCols: function () {
        let cols = 0;
        if (this.collapseFieldList) {
          cols = 1;
        } else if (this.$vuetify.breakpoint.lgAndUp) {
          cols = 2;
        } else {
          cols = 3;
        }

        return cols;
      },
      filteredTabs: function () {
        return this.tabsItems.filter((item) => item.id !== 3 || !this.infant);
      },
    },
    data: function () {
      return {
        saving: false,
        tab: 0,
        fieldList: [
          {
            id: 1,
            type: 'text',
            name: '',
            labelKey: 'TEXT',
            icon: 'mdi-form-textbox',
            gridColumns: 6,
            rules: {},
            options: [],
            dialogTab: 0,
          },
          {
            id: 2,
            type: 'date',
            name: '',
            labelKey: 'DATE',
            icon: 'mdi-calendar',
            gridColumns: 6,
            rules: {},
            options: [],
            dialogTab: 0,
          },
          {
            id: 3,
            type: 'slider',
            name: '',
            labelKey: 'SLIDER',
            icon: 'mdi-ray-vertex',
            gridColumns: 6,
            rules: {},
            options: [],
            dialogTab: 0,
          },
          {
            id: 4,
            type: 'check',
            name: '',
            labelKey: 'CHECK',
            icon: 'mdi-check',
            gridColumns: 2,
            rules: {},
            options: [],
            dialogTab: 0,
          },
          {
            id: 5,
            type: 'switch',
            name: '',
            labelKey: 'SWITCH',
            icon: 'mdi-toggle-switch-outline',
            gridColumns: 6,
            rules: {},
            options: [],
            dialogTab: 0,
          },
          {
            id: 6,
            type: 'textarea',
            name: '',
            labelKey: 'TEXTAREA',
            icon: 'mdi-form-textarea',
            gridColumns: 6,
            rules: {},
            options: [],
            dialogTab: 0,
          },
          {
            id: 7,
            type: 'select',
            name: '',
            labelKey: 'LIST',
            icon: 'mdi-arrow-down-bold-box-outline',
            gridColumns: 6,
            rules: {},
            options: [],
            dialogTab: 0,
          },
          {
            id: 8,
            type: 'file',
            name: '',
            labelKey: 'FILE',
            icon: 'mdi-file',
            gridColumns: 6,
            rules: {},
            options: [],
            dialogTab: 0,
          },
          {
            id: 9,
            type: 'integer',
            name: '',
            labelKey: 'INTEGER',
            icon: 'mdi-numeric',
            gridColumns: 6,
            rules: {},
            options: [],
            dialogTab: 0,
          },
          {
            id: 10,
            type: 'numeric',
            name: '',
            labelKey: 'NUMERIC',
            icon: 'mdi-decimal',
            gridColumns: 6,
            rules: {},
            options: [],
            dialogTab: 0,
          },
          {
            id: 11,
            type: 'radio',
            name: '',
            labelKey: 'RADIO',
            icon: 'mdi-radiobox-marked',
            gridColumns: 6,
            rules: {},
            options: [],
            dialogTab: 0,
          },
          {
            id: 12,
            type: 'title',
            name: '',
            labelKey: 'TITLE',
            icon: 'mdi-format-header-pound',
            gridColumns: 12,
            rules: {},
            options: [],
            dialogTab: 0,
          },
        ],
        dynamicFormFields: [],
        dynamicFormOptionsDeleted: [],
        fieldBoxFull: false,
        showDrawer: true,
        collapseFieldList: true,
        activeDynamicForm: false,
        showFieldGrid: false,
        showFormOnly: false,
        fieldConfigDialog: false,
        activatingDynamicForm: false,
        selectedField: {},
        rulesVisibilityConfig: {
          required: true,
          mask: false,
          maxLength: false,
          minValue: false,
          maxValue: false,
          decimalLength: false,
          orientation: false,
          step: false,
          multiple: false,
          fileAccept: false,
        },
        tempField: undefined,
        fileTypes: [
          {
            description: 'Todos',
            value: '*',
          },
          {
            description: 'Imagem (qualquer tipo)',
            value: 'image/*',
          },
          {
            description: 'Imagem (BMP)',
            value: 'image/bmp',
          },
          {
            description: 'Imagem (JPG)',
            value: 'image/jpg, image/jpeg',
          },
          {
            description: 'Imagem (PNG)',
            value: 'image/png',
          },
          {
            description: 'PDF',
            value: 'application/pdf',
          },
          {
            description: 'ZIP',
            value: 'application/zip',
          },
        ],
        tabsItems: [
          { name: 'Edição', id: 1 },
          { name: 'Apresentação', id: 2 },
          { name: 'Campos padrões', id: 3 },
        ],
        defaultFields: [
          {
            icon: 'mdi-format-header-pound',
            type: 'title',
            name: 'ADDRESS_CONTACT',
            labelKey: 'ADDRESS_CONTACT',
            gridColumns: 12,
          },
          {
            icon: 'mdi-phone',
            type: 'text',
            name: 'phone',
            labelKey: 'PHONE',
            gridColumns: 6,
          },
          {
            icon: 'mdi-at',
            type: 'text',
            name: 'email',
            labelKey: 'EMAIL',
            gridColumns: 6,
          },
          {
            icon: 'mdi-mailbox',
            type: 'text',
            name: 'zipCode',
            labelKey: 'ZIPCODE',
            gridColumns: 4,
          },
          {
            icon: 'mdi-map-marker',
            type: 'text',
            name: 'state',
            labelKey: 'STATE',
            gridColumns: 4,
          },
          {
            icon: 'mdi-city',
            type: 'text',
            name: 'city',
            labelKey: 'CITY',
            gridColumns: 4,
          },
          {
            icon: 'mdi-road-variant',
            type: 'text',
            name: 'street',
            labelKey: 'STREET',
            gridColumns: 8,
          },
          {
            icon: 'mdi-home-group-plus',
            type: 'text',
            name: 'neighborhood',
            labelKey: 'NEIGHBORHOOD',
            gridColumns: 4,
          },
          {
            icon: 'mdi-numeric',
            type: 'text',
            name: 'number',
            labelKey: 'NUMBER',
            gridColumns: 8,
          },
          {
            icon: 'mdi-axis-arrow-info',
            type: 'text',
            name: 'complement',
            labelKey: 'COMPLEMENT',
            gridColumns: 4,
          },
        ],
        defaultFieldList: [],
      };
    },
    mounted: async function () {
      if (this.dynamicForm) {
        this.activeDynamicForm = this.dynamicForm.active;
        this.getDefaultFields();
      }
      await this.getEntityFields();
    },
    methods: {
      getDefaultFields: async function () {
        const defaultFields = await api.getDefaultFields(this.dynamicForm.id);

        if (defaultFields?.length) {
          const auxDefaultFields = JSON.parse(JSON.stringify(this.defaultFields));
          this.defaultFields = [];

          auxDefaultFields.forEach((auxDefaultField) => {
            const matchedItem = defaultFields.find((field) => field.name === auxDefaultField.name);

            if (matchedItem) {
              this.defaultFields.push({ ...matchedItem, ...auxDefaultField });
            } else {
              this.defaultFieldList.push(auxDefaultField);
            }
          });
        }
      },
      handleActiveDynamicForm: async function () {
        try {
          await api.updateDynamicForm(this.dynamicForm.id, this.activeDynamicForm, this.infant);
        } catch (err) {
          this.activeDynamicForm = !this.activeDynamicForm;
        }
        this.sendUpdatedEvent();
      },
      getEntityFields: async function () {
        let dynamicForms;

        try {
          if (!this.dynamicForm) {
            dynamicForms = await api.getActiveFormByEntity(this.entity, this.entityId);
          } else {
            dynamicForms = await api.getDynamicFormById(this.dynamicForm.id);
          }
          this.dynamicFormFields = dynamicForms.dynamicFields;
        } catch (err) {
          if (err.response?.data?.error) {
            showError({ message: err.response.data.error });
            this.$router.push('/enrolled');
          } else {
            showError({ message: this.$t('SERVER_ERROR') });
          }
        }
        this.sendUpdatedEvent();
        this.dynamicFormFields.sort((a, b) => a.order - b.order);

        this.dynamicFormFields.forEach((field) => {
          field.rules = field.dynamicFieldRules ? field.dynamicFieldRules[0] : {};
          field.options = field.dynamicFieldOptions || [];

          if (['check', 'switch'].includes(field.type) && field.value) {
            field.value = field.value === 'true';
          }
          if (['file'].includes(field.type)) {
            this.fieldColorSet(field);
          }
        });
      },
      increaseFieldColumnSize: async function (fieldIndex, defaultFields) {
        if (defaultFields) {
          if (this.defaultFields[fieldIndex].gridColumns < 12) {
            this.defaultFields[fieldIndex].gridColumns++;
          } else {
            this.defaultFields[fieldIndex].gridColumns = 12;
          }
          this.saveDefaultFields();
        } else {
          this.dynamicFormFields = JSON.parse(JSON.stringify(this.dynamicFormFields));

          if (this.dynamicFormFields[fieldIndex].gridColumns < 12) {
            this.dynamicFormFields[fieldIndex].gridColumns++;
          } else {
            this.dynamicFormFields[fieldIndex].gridColumns = 12;
          }
          await api.updateDynamicField(this.dynamicFormFields[fieldIndex]);
        }
      },
      decreaseFieldColumnSize: async function (fieldIndex, defaultFields) {
        if (defaultFields) {
          if (this.defaultFields[fieldIndex].gridColumns > 1) {
            this.defaultFields[fieldIndex].gridColumns--;
          } else {
            this.defaultFields[fieldIndex].gridColumns = 1;
          }
          this.saveDefaultFields();
        } else {
          this.dynamicFormFields = JSON.parse(JSON.stringify(this.dynamicFormFields));

          if (this.dynamicFormFields[fieldIndex].gridColumns > 1) {
            this.dynamicFormFields[fieldIndex].gridColumns--;
          } else {
            this.dynamicFormFields[fieldIndex].gridColumns = 1;
          }
          await api.updateDynamicField(this.dynamicFormFields[fieldIndex]);
        }
      },
      addField: async function (field, defaultField) {
        if (defaultField) {
          field.order = this.defaultFields.length + 1;
          this.defaultFields.push(field);

          const listIndex = this.defaultFieldList.findIndex((item) => item.name === field.name);
          this.defaultFieldList.splice(listIndex, 1);

          this.saveDefaultFields();
        } else {
          field.order = this.dynamicFormFields.length + 1;
          this.dynamicFormFields.push(field);
          await api.saveDynamicField(this.entity, field, this.dynamicForm.id).then(() => {
            showSuccess({ message: 'Formulário atualizado' });
          });
          this.sendUpdatedEvent();
          this.getEntityFields();
        }
      },
      sendUpdatedEvent () {
        const mainField = this.dynamicFormFields.filter((field) => field.main);
        const hasMainField = mainField.length > 0;
        this.$emit('form-updated', hasMainField);
      },
      saveFieldConfig: async function (field) {
        field.labelKey = field.name;
        field.dialog = false;
        if (this.dynamicFormOptionsDeleted) {
          await api.deleteDynamicFieldOption(this.dynamicFormOptionsDeleted);
          this.dynamicFormOptionsDeleted = [];
        }
        await api.updateDynamicField(field);
        this.getEntityFields();
      },
      openFieldConfigDialog: function (field) {
        this.tempField = JSON.parse(JSON.stringify(field));
        field.dialogTab = 0;
        field.rules.required = true;
        this.getFieldTypeRules(field.type);
      },
      checkChanges: function (field, index) {
        const fieldAux = JSON.parse(JSON.stringify(field));
        const fieldRulesAux = JSON.parse(JSON.stringify(fieldAux.rules));
        const fieldOptionsAux = JSON.parse(JSON.stringify(fieldAux.options));

        this.dynamicFormOptionsDeleted = [];

        delete fieldAux.dialog;
        delete fieldAux.dialogTab;
        delete fieldAux.rules;
        delete fieldAux.options;

        const tempFieldAux = JSON.parse(JSON.stringify(this.tempField));
        const tempFieldRulesAux = JSON.parse(JSON.stringify(tempFieldAux.rules));
        const tempFieldOptionsAux = JSON.parse(JSON.stringify(tempFieldAux.options));

        delete tempFieldAux.dialog;
        delete tempFieldAux.dialogTab;
        delete tempFieldAux.rules;
        delete tempFieldAux.options;

        const diff = {
          any: false,
          types: [],
        };

        if (JSON.stringify(fieldAux) !== JSON.stringify(tempFieldAux)) {
          diff.any = true;
          diff.types.push(this.$t('GENERAL'));
        }
        if (JSON.stringify(fieldRulesAux) !== JSON.stringify(tempFieldRulesAux)) {
          diff.any = true;
          diff.types.push(this.$t('RULES'));
        }
        if (JSON.stringify(fieldOptionsAux) !== JSON.stringify(tempFieldOptionsAux)) {
          diff.any = true;
          diff.types.push(this.$t('OPTIONS'));
        }

        if (diff.any) {
          Swal.fire({
            icon: 'warning',
            title: this.$t('UNSAVED_CHANGES'),
            text: this.$t('DISCARD_CHANGES_IN_X?', { diffs: diff.types.map((type) => `'${type}'`) }),
            showCancelButton: true,
            cancelButtonText: this.$t('CANCEL'),
            confirmButtonColor: this.$vuetify.theme.themes.light.secondary,
          }).then((result) => {
            if (result.value) {
              this.dynamicFormFields[index] = this.tempField;
              this.closeFieldDialog(field);
            }
          });
        } else {
          this.closeFieldDialog(field);
        }
      },
      closeFieldDialog: function (field) {
        field.dialog = false;
        this.$forceUpdate();
      },
      getFieldTypeRules: function (fieldType) {
        this.rulesVisibilityConfig = {
          required: true,
          mask: fieldType === 'text',
          maxLength: ['text', 'textarea'].includes(fieldType),
          minValue: ['integer', 'numeric', 'slider'].includes(fieldType),
          maxValue: ['integer', 'numeric', 'slider'].includes(fieldType),
          decimalLength: fieldType === 'numeric',
          orientation: ['radio', 'slider'].includes(fieldType),
          step: ['numeric', 'slider'].includes(fieldType),
          multiple: false,
          fileAccept: fieldType === 'file',
        };
      },
      uploadFile: function (field) {
        this.$refs[`file-${field.id}`][0].$refs.input.click();
      },
      inputFile: function (field, $event) {
        field.file = $event;
        field.blob_value = $event;
        const parsedName = field.blob_value.path.split('\\');
        field.file_name = parsedName[parsedName.length - 1];
        const reader = new FileReader();
        reader.onload = (event) => {
          field.value = event.target.result;
        };

        reader.onloadend = () => {
          field.loading = false;
          this.$forceUpdate();
        };

        this.fieldColorSet(field);
        reader.readAsDataURL(field.blob_value);
        field.loading = true;
        this.$forceUpdate();
      },
      downloadFile: function (field) {
        const downloadLink = document.getElementById('downloadLink');
        let mimeType = field.value.split(';')[0].split(':')[1];
        let extension = mimeType.split('/')[1];
        const byteCharacters = window.atob(field.value.split(',')[1]);
        const byteArrays = new Uint8Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteArrays[i] = byteCharacters.charCodeAt(i);
        }

        if (mimeType.includes('zip')) {
          mimeType = 'application/octet-stream';
          extension = 'zip';
        }

        const blob = new Blob([byteArrays], { type: mimeType });
        const blobUrl = URL.createObjectURL(blob);

        downloadLink.href = blobUrl;
        downloadLink.download = `file.${extension}`;

        // Aciona o download automaticamente
        const clickEvent = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        downloadLink.dispatchEvent(clickEvent);
      },
      fieldColorSet: function (field) {
        field.file = {};
        // field.loading = false
        if (field.rules.fileAccept) {
          if (field.rules.fileAccept.includes('pdf')) {
            field.file.icon = 'mdi-file-pdf-box';
            field.file.color = '#DB1F1F';
          } else if (field.rules.fileAccept.includes('image')) {
            field.file.icon = 'mdi-image';
            field.file.color = '#27A6E0';
          } else if (field.rules.fileAccept.includes('zip') || field.file.name.includes('rar')) {
            field.file.icon = 'mdi-folder-zip-outline';
            field.file.color = '#D9D306';
          } else if (field.rules.fileAccept.includes('csv')) {
            field.file.icon = 'mdi-file-excel';
            field.file.color = '#1D6c41';
          } else if (field.rules.fileAccept.includes('doc')) {
            field.file.icon = 'mdi-file-word';
            field.file.color = '#1F61B9';
          } else {
            field.file.icon = 'mdi-file';
            field.file.color = '#A7A7A7';
          }
        }
      },
      onTextInput: function (field) {
        if (field.type === 'integer') {
          field.value = field.value.replace(/[^-?\d+$]/g, '');
        }
        if (field.type === 'numeric') {
          if (field.rules && field.rules.decimal_length) {
            field.value = Number(field.value).toFixed(field.rules.decimal_length);
          }
        }
      },
      getFieldOptions: function ({ options }) {
        return options.map((option) => {
          option.i18nLabel = this.$t(option.labelKey);
          return option;
        });
      },
      getFieldRules: function (field) {
        const fieldRules = [];
        if (field.rules && field.rules.required) {
          fieldRules.push((value) => (value !== undefined && value !== '') || this.$t('REQUIRED'));
        }
        if (field.rules && field.rules.maxLength) {
          fieldRules.push((value) => !value || (value && value.length <= field.rules.maxLength) || this.$t('MAX_LIMIT_REACHED'));
        }
        if (field.type === 'integer' || field.type === 'numeric') {
          if (field.rules && field.rules.minValue) {
            fieldRules.push((value) => !value || (value && Number(value)) >= field.rules.minValue || `${this.$t('INVALID_VALUE_MUST_BE_GREATER_THAN_X')}${field.rules.minValue}`);
          }
          if (field.rules && field.rules.maxValue) {
            fieldRules.push((value) => !value || (value && Number(value)) <= field.rules.maxValue || `${this.$t('INVALID_VALUE_MUST_BE_LOWER_THAN_X', { value: field.rules.maxValue })}`);
          }
        }
        return fieldRules;
      },
      saveReorder: function (defaultFields) {
        if (defaultFields) {
          this.saveDefaultFields();
        } else {
          this.dynamicFormFields.forEach((field, index) => {
            field.order = index + 1;
          });
          api.saveDynamicFieldOrder(this.dynamicFormFields).then(() => {
            showSuccess({ message: 'Formulário atualizado' });
          });
        }
      },
      removeField: function (index, defaultField) {
        Swal.fire({
          icon: 'warning',
          title: this.$t('FIELD_EXCLUSION'),
          text: this.$t('WANT_TO_REMOVE_FIELD?'),
          showCancelButton: true,
          cancelButtonText: this.$t('CANCEL'),
          confirmButtonColor: this.$vuetify.theme.themes.light.secondary,
        }).then((result) => {
          if (result.value) {
            if (defaultField) {
              this.defaultFieldList.push(this.defaultFields[index]);

              this.defaultFieldList.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              });

              this.defaultFields.splice(index, 1);
              this.saveDefaultFields();
            } else {
              const { id } = this.dynamicFormFields[index];
              const isLast = this.dynamicFormFields.length - 1 === index;
              api.deleteDynamicField(id).then(() => {
                this.dynamicFormFields.splice(index, 1);
                if (!isLast) {
                  this.saveReorder();
                }
                showSuccess({ message: 'Formulário atualizado' });
              });
            }
          }
        });
      },
      addFieldOption: function (field, index) {
        field.options.push({ name: '', value: '', order: field.options.length + 1 });
        this.optionsReorder(index);
        this.$forceUpdate();
      },
      removeFieldOption: function (field, fieldOptionsIndex) {
        Swal.fire({
          icon: 'warning',
          title: this.$t('OPTION_EXCLUSION'),
          text: this.$t('WANT_TO_REMOVE_OPTION?'),
          showCancelButton: true,
          cancelButtonText: this.$t('CANCEL'),
          confirmButtonColor: this.$vuetify.theme.themes.light.secondary,
        }).then((result) => {
          if (result.value) {
            const fieldOption = field.options.splice(fieldOptionsIndex, 1);
            this.dynamicFormOptionsDeleted.push(fieldOption[0].id);
          }
        });
      },
      optionsReorder: function (index) {
        this.dynamicFormFields[index].options.forEach((option, optionIndex) => {
          option.order = optionIndex + 1;
        });
      },
      redirectAndNotify: function (person) {
        const session = { ...this.$store.getters.session };
        session.person = person;
        this.$store.commit('session', session);
        this.$forceUpdate();
        showSuccess({ message: this.$t('DATA_SAVED_SUCCESSFULLY') });

        if (this.$route.name !== 'summary') {
          this.$router.push('/summary');
        }
      },
      saveDefaultFields: async function () {
        await api.saveDefaultFields(this.dynamicForm.id, this.defaultFields);
      },
      saveValues: async function () {
        this.saving = true;
        try {
          let res = {};
          const mainField = this.dynamicFormFields.find((d) => d.main);
          if (!mainField) {
            showError({ message: this.$t('MAIN_FIELD_REQUIRED') });
            this.saving = false;
            return;
          }
          const mainFieldValue = main_field.value;
          if (this.entity === 'PERSON' && !this.entityId) {
            res = await savePerson({ fullName: mainFieldValue, dynamicForm: true });
            this.entityId = res.id;
          } else if (this.entity === 'PERSON' && this.entityId) {
            res = await updatePerson({ id: this.entityId, fullName: mainFieldValue });
          }
          await api.saveValues(this.entityId, this.dynamicFormFields).then(() => {
            this.saving = false;
            this.getEntityFields();
          });
          this.redirectAndNotify(res);
        } catch (e) {
          this.saving = false;
          console.log(e); // eslint-disable-line
        }
      },
    },
  };
</script>

<style>
  .drag:hover, .dragOptions:hover {
    cursor: move;
  }
  .text-muted {
    margin-top: 5px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    min-height: 14px;
    min-width: 1px;
  }
  .border-1x-grey {
    border: 1px solid #eaeaea
  }
  .toolbar-title {
    color: white;
  }
</style>
