<template>
  <v-row>
    <v-col>
      <v-data-table :headers='headers' :items='dynamicFormFields' hide-default-header class='mb-3' disable-pagination hide-default-footer>
        <template #header='{ props }'>
          <th v-for='column in props.headers' :key='column.text' align='left' class='pa-4'> {{$t(column.text)}} </th>
        </template>
        <template #item.field='{ item }'>
          <span>{{ item.labelKey }}</span>
        </template>
        <template #item.main='{ item }'>
          <div class='d-flex align-center justify-center'>
            <v-checkbox v-model='item.main' class='ml-3 mt-3' style='align-self: center; justify-self: center' :disabled='["file", "title"].includes(item.type)' @change='setFieldAsMain(item)' />
          </div>
        </template>
        <template #item.show_on_listing='{ item }'>
          <div class='d-flex align-center justify-center'>
            <v-checkbox v-model='item.showOnListing' class='ml-3 mt-3' :disabled='["title", "file"].includes(item.type)' @change='updateField(item)' />
          </div>
        </template>
        <template #item.show_on_summary='{ item }'>
          <div class='d-flex align-center justify-center'>
            <v-checkbox v-model='item.showOnSummary' class='ml-3 mt-3' @change='updateField(item)' />
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
  import { updateDynamicField } from './api';

  export default {
    props: {
      dynamicForm: {
        type: Number,
        default: 0,
      },
      dynamicFormFields: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        headers: [
          {
            text: this.$t('FIELD'),
            value: 'field',
          },
          {
            text: 'MAIN_FIELD', // this.$('MAIN_FIELD'),
            value: 'main',
          },
          {
            text: 'SHOW_ON_LISTING', // this.$('SHOW_ON_LISTING'),
            value: 'show_on_listing',
          },
          {
            text: 'SHOW_ON_SUMMARY', // this.$('SHOW_ON_SUMMARY'),
            value: 'show_on_summary',
          },
        ],
        selectedForm: undefined,
      };
    },
    mounted () {
      this.selectedForm = this.dynamicForm;
    },
    methods: {
      getFieldOptions: function ({ options }) {
        return options.map((option) => {
          option.i18nLabel = this.$t(option.label_key);
          return option;
        });
      },
      updateField: async function (field) {
        await updateDynamicField(field);
        this.$emit('form-updated');
      },
      setFieldAsMain: async function (field) {
        for (let i = 0; i < this.dynamicFormFields.length; i++) {
          if (this.dynamicFormFields[i].id !== field.id) {
            this.dynamicFormFields[i].main = false;
          } else {
            this.dynamicFormFields[i].main = true;
          }
        }
        field.dynamic_forms_id = this.selectedForm;
        await this.updateField(field);
      },
    },
  };
</script>

<style>
  .border-below {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }
  .border-top {
    border: 1px solid rgba(0, 0, 0, 0.5);
  }
</style>
