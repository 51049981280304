import axios from 'axios';
import store from '@/store';

const url = process.env.VUE_APP_ENROLL_API_URL;
const { customerAcronym } = store.getters['auth/getUser']?.account;

export async function getActiveFormByEntity (entity: any = 'PERSON', entityId: any): Promise<any> {
  const { data } = await axios({
    url: `db/api/dynamic-forms/active?entity=${entity}&entityId=${entityId || ''}`,
    baseURL: url,
    method: 'GET',
    headers: {
      'x-cid': customerAcronym,
    },
    responseType: 'json',
    withCredentials: true,
  });
  data.dynamicFields.forEach((field: any) => {
    field.dialogTab = 0;
  });
  return data;
}

export async function getDynamicFormById (dynamicFormId: any): Promise<any> {
  const { data } = await axios({
    url: `db/api/dynamic-forms/${dynamicFormId}`,
    baseURL: url,
    method: 'GET',
    headers: {
      'x-cid': customerAcronym,
    },
    responseType: 'json',
    withCredentials: true,
  });
  data.dynamicFields.forEach((field: any) => {
    field.dialogTab = 0;
  });
  return data;
}

export async function createDynamicForm (entity: any, creatorId: any, infant: boolean): Promise<any> {
  const { data } = await axios({
    url: 'db/api/dynamic-forms',
    baseURL: url,
    method: 'POST',
    headers: {
      'x-cid': customerAcronym,
    },
    data: {
      entity,
      creatorId,
      infant,
    },
    responseType: 'json',
    withCredentials: true,
  });
  return data;
}

export async function updateDynamicForm (id: any, active: any, infant: boolean): Promise<any> {
  await axios({
    url: `db/api/dynamic-forms/${id}`,
    baseURL: url,
    method: 'PATCH',
    headers: {
      'x-cid': customerAcronym,
    },
    data: {
      active,
      infant,
    },
    responseType: 'json',
    withCredentials: true,
  });
}

export async function saveDynamicField (entity: any, field: any, dynamicFormId: any): Promise<any> {
  const { data } = await axios({
    url: 'db/api/dynamic-field',
    baseURL: url,
    method: 'POST',
    headers: {
      'x-cid': customerAcronym,
    },
    data: {
      entity,
      field,
      dynamicFormId,
    },
    responseType: 'json',
    withCredentials: true,
  });
  return data;
}

export async function updateDynamicField (field: any): Promise<any> {
  const { data } = await axios({
    url: 'db/api/dynamic-field',
    baseURL: url,
    method: 'PUT',
    headers: {
      'x-cid': customerAcronym,
    },
    data: {
      field,
    },
    responseType: 'json',
    withCredentials: true,
  });
  return data;
}

export async function deleteDynamicFieldOption (ids: any): Promise<any> {
  const { data } = await axios({
    url: 'db/api/dynamic-field-option',
    baseURL: url,
    method: 'DELETE',
    headers: {
      'x-cid': customerAcronym,
    },
    data: {
      ids,
    },
    responseType: 'json',
    withCredentials: true,
  });
  return data;
}

export async function deleteDynamicField (id: any): Promise<any> {
  const { data } = await axios({
    url: 'db/api/dynamic-field',
    baseURL: url,
    method: 'DELETE',
    headers: {
      'x-cid': customerAcronym,
    },
    data: {
      dynamicFieldId: id,
    },
    responseType: 'json',
    withCredentials: true,
  });
  return data;
}

export async function saveDynamicFieldOrder (fields: any): Promise<any> {
  const { data } = await axios({
    url: 'db/api/dynamic-field-order',
    baseURL: url,
    method: 'PUT',
    headers: {
      'x-cid': customerAcronym,
    },
    data: {
      fields,
    },
    responseType: 'json',
    withCredentials: true,
  });
  return data;
}

export async function saveValues (entityId: any, values: any): Promise<any> {
  const { data } = await axios({
    url: 'db/api/dynamic-field-values',
    baseURL: url,
    method: 'POST',
    headers: {
      'x-cid': customerAcronym,
    },
    data: {
      entityId,
      values,
    },
    responseType: 'json',
    withCredentials: true,
  });
  return data;
}

export async function getAllEntities (): Promise<any> {
  const { data } = await axios({
    url: 'db/api/dynamic-form-entities',
    baseURL: url,
    method: 'GET',
    headers: {
      'x-cid': customerAcronym,
    },
    responseType: 'json',
    withCredentials: true,
  });
  return data;
}

export async function getAllForms (infant: boolean): Promise<any> {
  const { data } = await axios({
    url: 'db/api/dynamic-forms',
    baseURL: url,
    method: 'GET',
    headers: {
      'x-cid': customerAcronym,
    },
    params: {
      infant,
    },
    responseType: 'json',
    withCredentials: true,
  });
  return data;
}

export async function exportForms (formsId: any): Promise<any> {
  const { data } = await axios({
    url: 'db/api/dynamic-forms/export',
    baseURL: url,
    method: 'POST',
    headers: {
      'x-cid': customerAcronym,
    },
    responseType: 'json',
    withCredentials: true,
    data: {
      formsId,
    },
  });
  return { data };
}

export async function importForms (dynamicForms: any): Promise<any> {
  await axios({
    url: 'db/api/dynamic-forms/import',
    baseURL: url,
    method: 'POST',
    headers: {
      'x-cid': customerAcronym,
    },
    responseType: 'json',
    withCredentials: true,
    data: {
      dynamicForms,
    },
  });
}

export async function getDefaultFields (formId: number): Promise<any[]> {
  const { data } = await axios({
    url: `db/api/dynamic-form/default-fields/${formId}`,
    baseURL: url,
    method: 'GET',
    headers: {
      'x-cid': customerAcronym,
    },
    responseType: 'json',
    withCredentials: true,
  });

  return data ? data.defaultFields : [];
}

export async function saveDefaultFields (formId: number, defaultFields: any[]): Promise<any> {
  if (defaultFields) {
    await axios({
      url: 'db/api/dynamic-form/default-fields',
      baseURL: url,
      method: 'POST',
      headers: {
        'x-cid': customerAcronym,
      },
      responseType: 'json',
      withCredentials: true,
      data: {
        formId,
        defaultFields: defaultFields.length ? defaultFields.map((field: any) => { // eslint-disable-line
          return {
            name: field.name,
            gridColumns: field.gridColumns,
            required: !!field.required,
          };
        }) : [],
      },
    });
  }
}

export default {
  createDynamicForm,
  deleteDynamicField,
  deleteDynamicFieldOption,
  exportForms,
  getActiveFormByEntity,
  getAllEntities,
  getAllForms,
  getDefaultFields,
  getDynamicFormById,
  importForms,
  saveDefaultFields,
  saveDynamicField,
  saveDynamicFieldOrder,
  saveValues,
  updateDynamicField,
  updateDynamicForm,
};
